.footerroot {
    margin-top:-10px;
    padding: 0px 16px 0 16px;
    font-family:Roboto-Medium;
    height: 100%;
    font-weight: 400;
    font-size: 1vw;
    background-color: black;
    -webkit-text-size-adjust : 100%;
  }
.bgimage{
 position: absolute;
 left: 0;
 z-index: 0;
 width: 100%;
 height: 110%;
 margin-top: 1%;
}
.bgimage1{
  display: none;
  z-index: 1 ;
}
.footergrid{
  top:10%;
  padding-top: 20px;
  background-color: black;
}
.middlegrid{
  min-width: 320px;
  text-align:left;
  margin-top:18%;
  /* [theme.breakpoints.down('sm')]: {
    marginTop:"0%",
    paddingTop:"0%",
  }, */
}
.links{
    line-height: 2.9;
    color: #FFFFFF;
    text-decoration: none;   
    font-size: 1.3vw;
    margin-left: 2%;
    letter-spacing: 0.1vw;
}

.links:hover{
    color:gray;
}

.linklist li:hover .linksIcon{  
  visibility: visible;
}

.linklist{
    list-style: none;
    padding-top: 0;
    margin-left:20%;
}

.linksIcon{
  visibility: hidden; 
  margin-right: 5%;
  width: 5%;
}

.socialicon{
  width: 9%;
  margin-right: 0%;
  margin-left: 10%;
}
.social{
  font-family:Roboto-Medium; /*CaviarDreams*/
  margin:2%;
  font-size: 1.5vw;
  letter-spacing:0.1vw; 
  text-decoration: none;
  color: #FFFFFF;
}
.social:hover{
  color: gray;
}
.subFooter{
  text-align:center;
  padding:0; 
  font-family:Roboto-Medium;
  font-size:1vw;
  margin-top: 5%;
  padding-bottom: 0;
  margin-bottom: -2.5%;
  background-color: black;
  top:10%
}
.sections{
     min-width: 320px;
      text-align:left;
      margin-top:20%;
}
@media only screen and (max-width: 600px) {
  

     .footerroot {   
      margin-top:20px;
      padding: 0px 16px 0 16px;
      font-family:Roboto-Medium;     
      font-weight: 400;
      font-size: 1vw;
    -webkit-text-size-adjust : 100%;

    }
    .bgimage{
      display: none;
    }
    .bgimage1{
      display: block;
      position: absolute;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 116vw;
    }
    
  .footergrid{
    top: 10%;
    padding-top: 20px;
    background-color: black;
  }
  .LeftGrid{
    padding-left: 3.1vw;
  }
  .links{
      line-height: 2.5;
      color: #FFFFFF;
      text-decoration: none;      
      font-size: 2.5vw;
      margin-left: 1.5%;
      letter-spacing: 0vw;
      
  }
  .linksIcon{
    width:1.8%;
    margin-right: 2%;
    margin-left: 3%;
  }
  .links:hover{
      color:gray;
      list-style-image:url("../images/tringal.png");
      list-style-type: square;
  }
  .linklist{
      list-style: none;
          padding-top: 0;
          margin-left:-16%;
         
  }
  .socialicon{
    width: 8%;
    margin-right: 2%;
    margin-left: -2%;

  }
  .social{
    font-size: 2.5vw;
  }
  
  .subFooter{
   
    font-size:2vw;
  
    letter-spacing: 0.1vw;
    margin-top: 5%;
  }
}
@media only screen and (max-width: 550px) {
  .bgimage1{
    height: 120vw;
  }}
@media only screen and (max-width: 450px) {
  .bgimage1{
    height: 127vw;
  }
  .subFooter{
   margin-bottom: -2%;
   margin-top: 6%;
  }
  .links{
    font-size: 9px;
  }
  }
  @media only screen and (max-width: 400px) {
    .bgimage1{
      height: 131vw;
    }}
    @media only screen and (max-width: 370px) {
      .subFooter{
        margin-bottom: -2%;
        margin-top: 2%;
       }
     }
@media only screen and (max-width: 350px) {
.bgimage1{
  height: 136vw;
}
.links{
  font-size: 8px;
}
.subFooter{
 margin-bottom: -2%;
 margin-top: 2%;
}
}
@media only screen and (max-width: 300px) {
  .bgimage1{
    height: 147vw;
  }
  .links{
    font-size: ;
  }
}

@media  (max-width: 1200px) {
  .bgimage{
    height: 61%;
  }
  
  }
  @media (max-width: 1000px) {
    .bgimage{
      height: 54%;
    }}
  @media (max-width: 800px) {
    .bgimage{
      height: 45%;
    }
    }
    @media (max-width: 750px) {
      .bgimage{
        height: 45vw;
      }
      }
     
  @media (min-width: 1200px) {
    .bgimage{
      height: 41vw;
    }
    .subFooter{
      margin-top: 8%;
      margin-bottom: -10%;
    }
    }
    
    
   
    @media (min-width: 1530px){
      .bgimage{
        height: 40vw;
      }
    }

@media only screen and (min-device-width: 350px)  and (max-device-width:400px) {
  .bgimage{
    height: 215vw;
    }
}
@media only screen and (max-device-width: 350px) {
  .bgimage{
    height: 217vw;
  }
}
@media (max-device-width: 300px) {
  .bgimage{
    height: 223vw;
  }
}


@media only screen and (min-device-width: 400px) and (max-device-width:470px) {
  .bgimage{
    height: 105%;
    }
}
@media only screen and (max-device-width:600px) {
  .bgimage{
    height: 100%;
    }
    .links:hover{
      list-style-image: none;
    }
}

@media only screen and (min-device-width: 600px) and (max-device-width:750px) {
  .bgimage{
    height: 47vw;
    }
    .subFooter{
      margin-top: 5%;
    }
}
@media only screen and (min-device-width: 750px) and (max-device-width:900px) {
  .bgimage{
    height: 53vw;
    }
    .subFooter{
      margin-top: 16%;
    }
  }
  @media only screen and (min-device-width: 900px) and (max-device-width:1100px) {
    .bgimage{
      height: 53vw;
      }
      .subFooter{
        margin-top: 16%;
      }
    }
    @media only screen and (min-device-width: 1100px) and (max-device-width:1200px) {
      .bgimage{
        height: 51vw;
        }
        .subFooter{
          margin-top: 16%;
        }
      }