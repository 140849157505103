.App {
  text-align: center;
  height: 100%;
  flex-direction: column;
  scroll-behavior: smooth;
}
.chatbutton{
  position: fixed;
  cursor: pointer;
  right:4%;
  top: 1vw;
  z-index: 1000;
  color: white;
  border-radius: 15px ;
  border: none;
  font-size: 1.2vw;
  font-family: Fredoka One;
  padding: 0.7%;
  background: #438dee; /*background: #FF891C;*/
  z-index: 10000;
  
}
.chatbutton:focus{
  border: none;
  outline: none;
}
.chaticon{
  display:none;
}
@media only screen and (max-width: 960px)
{
  .chatbutton{
  top: 0.5vw;
  right:2%;
  border-radius: 15px ;
  border: none;
  font-size: 1.8vw;
  font-family: Fredoka One;
  padding: 0.7%;

  }
}
@media only screen and (max-width: 800px){
  .chatbutton{
    top:2%;
    font-size: 1.8vw;
  top: 1%;

  }
}


@media only screen and (max-width: 600px){
  .chatbutton{
    display: none;
  }
  .chaticon{
    display: block;
    position: fixed;
  right:18%;
  top: 1.5%;
 color: white;
  cursor: pointer;
 
  z-index: 10000;
  }
}