
.privhead{
    margin-top:15px;
    font-family: Fredoka One;
    color: white;
    font-size: 8vw;
    padding-top: 10%;
    padding-bottom: 10%;

}
.headblock{
    background-color: black;
    height: 10%;
}
.lowerblock{
    text-align: left;
    margin-left: 5%;
    font-family: Humanist777;
}
