.root{
    margin-top: 5%;
    margin-bottom: 0;
}
.leftapply{
   width: 50%;
}
.headingApply{
    font-family: CaviarDreams;
    font-size: 5vw;
    color: black;
    margin-left: 5%;
    margin-top: 9%;
    text-align: left;
}
.rightapply{
    width: 50%;
    display: ;
}
.submittedText{
    font-family: CaviarDreams;
    font-size: 1.5vw;
    color: black;
    margin-left: ;
    margin-top: 9%;
   display:none;
}
.form{
    margin-left: 0;
}
.formin{
    margin-left: 0;
    margin-top: 2%;
    border: 0.157vw solid black;
    border-radius: 0;
    font-size: 1.5vw;
    background-color: rgb(182, 175, 175);
    height: 3vw;
    font-family: CaviarDreams;
    width: 80%;
}
.label{
    font-family: CaviarDreams;
    text-align: left;
    margin-left: 9%;
    margin-top: 5%;
    margin-bottom: 0;
    font-size: 1.5vw;
}
.submitbt{
    width:10vw;
    margin-top: 4vw;
    margin-left: 62%;
    padding: 2%;
    /* border: 4px solid black; */
    border: none;
    background-color: #2D85F3;
    font-weight: bold;
    font-size: medium;
    cursor: pointer;
    color: white;
    border-radius: 5px;
}
@media only screen and (max-width: 600px) {
   
    .leftapply{
       width: 50%;
    }
    .headingApply{
        font-family: CaviarDreams;
        font-size: 10vw;
        color: black;
        margin-left: 8%;
        margin-top: 13%;
    }
    .rightapply{
        width: 50%;
     
    }
    .submittedText{
        
        font-size: 8vw;

    }
    .formapply{
        margin-left: 0;
    }

    .formin{
        margin-left: 5%;
        margin-top: 4%;
        border: 2px solid black;
        border-radius: 0;
        font-size: 6vw;
        background-color: rgb(182, 175, 175);
        height: 8vw;
        font-family: CaviarDreams;
        width: 85%;
    }
    .label{
        font-family: CaviarDreams;
        text-align: left;
        margin-left: 9%;
        margin-top: 5%;
        margin-bottom: 0;
        font-size: 4vw;
    }
    .submitbt{
        width:40%;
        margin-top: 10%;
        margin-left: 52%;
        margin-bottom: 10%;
        padding: 4%;
        /* border: 4px solid black; */
        border: none;
        background-color: #2D85F3;
        font-weight: bold;
        font-size: medium;
        cursor: pointer;
        color: white;
        border-radius: 5px;
    }
}