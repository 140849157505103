body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Fredoka One', cursive,'Humanist777','CaviarDreams';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace,'Fredoka One', cursive;
}
@font-face {
  font-family: 'Humanist777';
  src: local('Humanist'), url(./fonts/Humanist777BoldCondensedBT.ttf) format('truetype');
}
@font-face {
  font-family: 'CaviarDreams';
  src: local('CaviarDreams'), url(./fonts/CaviarDreams.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./fonts/Roboto-Medium.ttf) format('truetype');
}
.Upper{
  position: absolute;
 
  display: flex;
  background-color: black;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 3;
}