.logoA{
    float: left;
    width: 12vw;
    padding-bottom: 5%;
}
.LnButtons{
   margin-top: 5%;
   padding-bottom: 10%;
   text-align: right;

}
.lnbutton{
   border-radius: 5%;
   border: none;
    color:#FFFFFF;
    font-size: 1.5vw;
  margin-right:5% ;
  padding: 1% 2%;
    cursor: pointer;
font-family: Arial, Helvetica, sans-serif;
    background-color:#000000;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.lnbutton:hover{
    background-color: transparent;
    color: black;
    border: none;
    box-shadow:  none;
}
.lnbutton:active {
    background-color: transparent;
    color: black;
    border: none;
    box-shadow:  none;

}
.lnbutton:focus{
    border: none;
    box-shadow:  none;

}
.MainGrid{
    margin-top:10% ;
}
.sketch{
    max-width:920px;
    min-width:200px;
    width:100%;
    max-height:920px;
}
.sketchDetail{
    padding-top:"30px";
    text-align: right;
}
.details{
    color: white;
    background-color: black;
    padding: 50% 100px 50% 50px;
    max-height: 900px;
    text-align: left;
}
.imgright{
    max-width:720px;
min-width:200px;
width:90%;
max-height:720px;
margin-top: 10%;
text-align:center;
}
@media only screen and (max-width:960px){
    .details{
        
        padding: 35% 50px 35% 50px;
margin-top: 5%;
        
        text-align: left;
    }
    .lnbuttons{
        font-size: 2.5vw;
       padding: 5% 10% 5% 10%;
        }
}
@media only screen and (max-width:600px)
{
    .logoA{
        width: 35%;
    }
    .LnButtons{
        margin-top: 5%;
        padding-bottom: 15%;
        text-align: center;
     
     }
.lnbutton{
font-size: 4vw;
padding: 3% 5%;


}
    .sketchDetail{
        text-align: left;
    }
    .details{
        
        padding: 50px 50px 50px 50px;
        
        text-align: left;
    }
    .imright{
        max-width:720px;
    min-width:200px;
    width:90%;
    max-height:720px;
margin-top: 0%;
    
    text-align:center;
    }
}

