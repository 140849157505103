
.menuIcon{
    display: none;
    visibility: hidden;
}

.closeBtn{
    visibility: hidden;
}
.sidenav{
  display: none;
   
}
.nav{
    padding-top: 1.7%;
}
.logholder{
    width: 10%;
}
.nav-logo{
    text-decoration:none;
    height:100%;
    width: 75%;
    margin-left:20%;
}
@media only screen and (max-width: 1000px) {
    .nav{
        padding-top: 2%;
    }  
}
@media only screen and (max-width: 600px) {
    .nav-logo{
        width: 140%;
    }
    .nav{
        display: none;
    }
    .navroot{
        height: 6%;
    }
    .menuIcon{
        visibility: visible;
        display: block;
        color: white;
        z-index: 1000;
        position: fixed;
        right: 6%;
        top:1.2%;
        cursor: pointer;
        
    }
    .sidenav{
        display: block;
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1000;
        top: 0;
        right: 0;
        background-color: #111;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }
    .sidenav a {
        padding: 10px 12px 10px 32px;
        text-decoration: none;
        border-bottom: 2px solid white;
        font-size: 25px;
        color: white;
        display: block;
        transition: 0.3s;
        font-family: Fredoka One;
      }
      
      .sidenav a:hover {
        color: #f1f1f1;
      }
      
      .sidenav .closeBtn {
        visibility:hidden;
        color:white;          
        position: fixed;
        top: 0.8%;
        right: 3%;
        font-size: 35px;
        margin-left: 10px;
        cursor: pointer;
      }
      
}
@media only screen and (max-width:470px){
    
    .nav-logo{
        width: 175%;
    }
}
