
.Header{
    display: block;
    margin-top: -1vw;
    text-align: center;
}
.headings{
    font-family: CaviarDreams;
    font-size: 3vw;
}
.headerImg{
    position: ;
    display: inline-block;
     width: 71vw;
     margin-right: ;
     margin-top: -2%;
}
.subheading{
    position: absolute;
    top:23vw;
    left:37% ;
    font-size: 1vw;
    font-family: Fredoka One;
    letter-spacing: 0.1vw;
}
.Heading{
    position: absolute;
    top:4vw;
    left:33.5% ;
    font-size: 7vw;
    font-family: Fredoka One;
    letter-spacing: .3vw;
    color: white;
}
.Heading::first-letter{
    font-size: 11vw ;
}
.logo{
    position: absolute;
    background-color: transparent;
    top:14vw;
    left: 18%;
    width: 7vw;
    z-index: 1;
}
.whatweoffer{
    font-family: Humanist777;
    margin: 3.5vw;
    font-size: 2vw;
    font-weight: lighter;
}
.grayblock{
    background-color:#E5E5E5;
    text-align: center;
    width: 75%;
    margin-left: 10vw;
    font-size: 1.8vw;
    padding: 1.5vw;
    margin-top: 5%;
    margin-bottom: 7%;
}
.blocktext{
    padding: 0;
    margin: 0; 
}.gurugram{
    display: flex;
    flex-direction: row;
    width: 70%;
    margin-left: 15%;
    font-weight: lighter;
}
.gurugramImg{
width: 24vw;
height: 22vw;
margin-left:5% ;
margin-top: 2%;
transition: 0.3s all ease-in-out;
}
.gurugramImg:hover{
    transform: scale(1.1);
}
.GList{
    /* list-style-image: url("../images/TRINGEL-min.png"); */
    list-style: none;
    width: 30vw;
    text-align: left;
    font-size: 2vw;
    margin-left: 5%;
    font-weight: ;
}
.triangle-right {
	width: 5%;
}
.glistitem{
line-height: 2;
margin-top: -1%;
}
.glistitem:hover{
    color: gray;
}
.People{
    margin-top: 7%;
    margin-left: 12%;
    margin-bottom: 5%;
    text-align: left;
    width: 76%;
    font-size: 2.2vw;
    font-weight: ;
    line-height: 100%;
}
.openings{
    
    font-size: 2vw;
    width: 90%;
    margin-left: 5%
}
.openingGrid{
    margin-top: 6%;
 margin-bottom: 2%;
}
.gridItem{
   position: relative;
  display: flex;
  flex-direction: column;
  height: 25vw;
  box-shadow: 10px 10px solid grey;
}
.job{
    position: relative;
    /* display:block; 
    background-color: black;
    color: white;
    padding-top: 10vw;
    padding-bottom:12vw;
    margin:5%;
    text-align: center;
    box-shadow: 10px 10px solid grey; */

}

.Upper{
    position: absolute;
    text-align: center;
    font-weight: bold;
    display:block;
    font-weight: bold;
    background-color: black;
    color: white;
    height: 0vw;
    width: 102%;
    z-index: 3;
    padding-top: 9.5vw;
    padding-bottom: 12vw;
    transition: all 0.2s ease-in-out;
   
}
.job:hover .Upper{

    /* transform: translateX(-50%) translateY(-50%);  */
    opacity: 0;
    visibility: hidden;
}

.Down{
    text-align: left;
    z-index: 1;
    display:block;
    position: absolute;
    width: 98%;
    height: 20.5vw;
    font-weight: bold;
    background-color: black;
    color: white;
    padding-top: 1vw;
    padding-left: 1vw;
    padding-bottom: ;
    box-shadow: 0.5vw 0.5vw rgb(233, 230, 230);
 
}
.Down:hover .Upper{
    z-index: -2;
}
.desHead{
    font-size: 2vw;
    margin-top: 0;
}
.description{
    font-size: 1.2vw;
    color: gray;
    margin-top: 1vw;
    line-height: 123%;
    font-weight: 500;  
    text-align: left;
}

.applydes{
    margin-left: 30%;
    cursor: pointer;
    background-color: #2D85F3;
    border-radius: 0.5vw;
    border: none;
    color: white;
    padding: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    font-weight: 700;
    font-size: 1.5vw
}
.applydes:focus .applydes:active{
    border: none;
    background-color: #0d51a3;
}
.applydes:hover{
    background-color: #0d51a3;
}
.applydes:hover .Upper{
    display: none;
}

.descriptionList{
    color: rgb(112, 107, 107);
    text-align: left;
    font-size: 1.2vw;
    font-weight: 500;
    margin-bottom: 1vw;
    list-style: square ;
    overflow:hidden;
    padding-left: 0;
    
}

.descriptionList li{

    margin-left: 1%;
    display: inline;
    line-height: 2;
}
.descriptionList li::before{
content: '\ffed';
margin-right: 1%;
}



.quote{
    width: 50%;
    margin-left: 24%;
    font-size: 4vw;
    line-height: 100%;
    padding-top: 5%;
}
@media only screen and (max-width: 600px) {
    .GList{
    margin-left: 0; font-size: 3vw;
    width: 110%; font-family: CaviarDreams;
    }
    .gurugram{
        width: 80%;
        margin-left: 8%;
    }
    
    .gurugramImg{width:32vw;height:29vw; margin-top: 15%;margin-left: 0;}
    
    .logo{
        width: 10%;
        left: 12%;
        top:12%
    }
    .headerImg{display:inline-block;width:90vw;margin-top:5%;margin-left: 0%;}
    .Heading{position:absolute;top:7%;left:30%;font-size:8vw;}
    .Heading::first-letter{ font-size: 12vw;}
    .subheading{top:34vw;left:29%;font-size:1.5vw;
    letter-spacing: 0.1vw;}
    .headings{ font-size: 5vw;}
    .whatweoffer{margin:3.5vw;font-size:4vw;margin-left: 5%;}
    .grayblock{width:95%;margin-left:0%; font-size: 3vw;}.People{margin-top:10%;margin-left:8%;text-align:left;width:88%;line-height: 1.5;font-size: 4vw;font-family: CaviarDreams;font-weight: 700;}
    .openings{
        margin-top: 10%;
       
    }
    .openingGrid{
        width: 70%;
        margin-left: 15%;
    }
    .gridItem{
        margin-top: 10%;
        height: 56vw;
     box-shadow: 20px 20px solid grey;

    }
    .deslisItem{
       font-size: 10vw;

    }
    .Down{text-align: center; padding-top:2vw;padding-bottom:20vw; height: 23vw;padding-right: 3%;
    }.desHead{font-size:4vw;margin-top:1%;padding-bottom: 2%;font-family: Humanist777;}.description{font-size:3vw;margin-top: 4%;font-family: Humanist777;margin-bottom: 0;font-weight: 500; text-align: center;}
    .applydes{margin-left:0%; margin-top: 0; 
        cursor: pointer;
        background-color: #2D85F3;
        border-radius: 0.5vw;
        border: none;
        color: white;
        padding: 1vw;
        padding-left: 5vw;
        padding-right: 5vw;
        font-weight: 700;
        font-size: 5vw }
    .descriptionList{margin-top:5%;margin-left:3%;margin-bottom: 5%;margin-right: 3%; font-size: 2.7vw; line-height: 100%; font-family: Humanist777}
    li:hover{color:#fff;list-style-image:none;}.quote{width:50%;margin-left:24%;font-size:4vw;line-height:100%;margin-top:0;margin-bottom: 20%; font-family: Arial;}
    .Upper{padding-top:20vw;padding-bottom:24vw; font-size: 5vw; font-family: Arial, Helvetica, sans-serif;}
}
@media only screen and (max-width: 1200px) {

.desHead{
    margin-bottom: -3%;
}
.quote{width:50%;margin-left:24%;font-size:4vw;line-height:100%;margin-top:10%;
padding-top: 5%;
}
}
@media only screen and (max-width: 420px) {
.subheading{
    font-size: 5.8px;
}
}