.wrapper{
    position:fixed;
        top:10%;
        right:2%;
        width:30%;
        /* height:24vw; */
        z-index:10001 ;
        border-radius: 5%;
        background:transparent;

}
.header{
    color: white;
    margin: -2px;
    font-size: 2.5vw;
    font-family: Humanist777;
}
.chatBox{
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    
    height:100%;
    background:transparent;
    border-radius:20px;
    
}
.chatBox:hover{
    cursor:pointer;
}
.chatFoot{
    background:#000;
    height:100%;
    padding-top:1vw;
    border-radius:20px;   
}
.formIn
{
    width: 80%;
    padding: 0.7vw;
    font-family: Humanist777;
    font-size: 1.3vw;
    border-radius: 10px;
    border: none;
    margin-top: 0.8vw;
}
.closebt{
    font-family: Humanist777;
    font-size: 1.5vw;
    border-radius: 7px;
    border: none;
    top:10%;
    margin: 5%;
    margin-left: 10%;
    padding: 0.8vw;
    padding-left: 4%;
    padding-right: 4%;
    color:gray;

}
.closebt:hover{
    cursor: pointer;
    color: black;
}
.closebt:focus{
    outline: none;
}
.submitted{
    display:none;
    background:black;
    height:50%;
    padding-top: 5%;
    z-index:1002;
    color:white;
    
    border-radius:20px;
    box-shadow:0 15px 25px rgba(0,0,0,.6);
}
.submittxt{
    font-family: Humanist777;
    font-size:3.43vw;
}
@media only screen and (max-width: 600px)
{
   
    
    .wrapper{
        position:fixed;
            top:7.5%;
            right:2%;
            width:58%;
            /* height:35%; */
            z-index:10001 ;
            border-radius: 1%;
            background:transparent;
    
    }
    .header{
        color: white;
        margin: 2px;
        font-size: 130%;
        font-family: Humanist777;
    }
    .chatBox{
        box-shadow: 0 15px 25px rgba(0,0,0,.6);
        
        height:100%;
        background:transparent;
        border-radius:20px;
        
    }
    .chatBox:hover{
        cursor:pointer;
    }
    .chatFoot{
       
        height:100%;
        padding-top:1vw;
        border-radius:10px;   
    }
    .formIn
    {
        width: 85%;
        padding: 1%;
        font-family: Humanist777;
        font-size: 100%;
        border-radius: 4px;
        border: none;
        margin: 1.8vw;
    }
    .closebt{
        font-family: Humanist777;
        font-size: 100%;
        border-radius: 7px;
        border: none;
        top:12%;
        margin: 3.5%;
        margin-left: 5%;
        padding: 2%;
        padding-left: 4%;
        padding-right: 4%;
        color:gray;
    
    }
    .closebt:focus{
        outline: none;
    }
    .submitted{
        display:none;
        background:black;
        height:50%;
        padding-top: 20%;
        padding-bottom: 18%;
        
    }
}