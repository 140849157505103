.root1{
    display: flex;
    margin-bottom: ;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    width: 100%;

}
.Header{

}
.bigtext{
    text-align: right;
    margin-top: 10vw;
    margin-right: 7%;
    font-size: 5.5vw;
    font-family: CaviarDreams;
    font-weight: 100;
}
.formgrid{
    margin-bottom: 4%;
    
}
.left{
    text-align: left;
    margin-bottom: 10%;
    width: 100%;  
}
.form{
    margin-top: 4%;
    margin-left: 5%;
    margin-bottom: 10%;
    width: 150%;
    font-family: CaviarDreams;
    font-weight: 800;
    display: ;
}
.first{
    margin-left: 0%;
}
.second{
    margin-left: 0%;
}
.forminput{ 
    margin-left: 20%;
    font-size: 2.5vw;
    border-radius: 3px;
    border: 1.8px solid black;
 
    background-color: rgb(182, 175, 175);
    height: 3vw;
    font-family: CaviarDreams;
    width: 100%;
}
    .required:after {
        content:" *";
        color: red;
}
.CEinput{
    margin-left: ;
    font-size: 1.5vw;
    border-radius: 3px;
    border: 2px solid rgb(30, 125, 233);
    background-color: rgb(182, 175, 175);
    height: 3vw;
    width: 59%;
}
.CElabel{
    margin-left: 13%;
    margin-top: 5%;
    font-family: CaviarDreams;
    font-weight: 800;
}
.uplabel{
    margin-left: 5%;
    font-size: 1.2vw;
    letter-spacing: 0.1vw;
}
.downlabel{
    padding-top: 0;
    margin-left: 5%;
    letter-spacing: 0.1vw;
    margin-top: 0;
    margin-bottom: ;
    font-size: 1.2vw;

}
.messageinput{
    margin-left: 4%;
    margin-top: 1.5%;
    font-size: 1.5vw;
    border-radius: 3px;
    font-family: CaviarDreams;
    border: 1.8px solid black;
    background-color: rgb(182, 175, 175);
    height: 13.2vw;
    width: 35vw;
}
.formlabel{
    margin-left: 21%;
    margin-top: 1%;
    font-size: 1.2vw;
    letter-spacing: 0.1vw;

}
.messagelabel{
    float: left;
    margin-left: 16%;
    margin-top: 5%;
    font-family: CaviarDreams;
    font-weight: 800;
    font-size: 1.2vw;
    letter-spacing: 0.1vw;
}
.pair{
    float: ;  
}
.right{
    display: flex;
    flex-direction: column;
}
.submit{
    width: 20%;
    margin-top: 7%;
    margin-left: 68%;
    padding: 2%;
    /* border: 4px solid black; */
    border: none;
    background-color: #2D85F3;
    font-weight: bold;
    font-size: 1.5vw;
    cursor: pointer;
    color: white;
    border-radius: 5px;
}
.reachUs{
    text-align: right;
    margin-right: 7%;
    font-family: CaviarDreams;
    font-size: 1.5vw;
    line-height: 0.5vw;
    margin-bottom: 5%;
}
.submittedtext{
    display: none;
    font-family: CaviarDreams;
    font-size: 1.5vw;
}
@media only screen and (max-width: 600px) {
 
 


.form{
    margin-top: 4%;
    margin-left: 0%;
    margin-bottom: 10%;
    width: 82%;
    font-family: CaviarDreams;
    font-weight: 800;
}
.forminput{ 
    margin-left: 20%;
    font-size: 2.5vw;
    border-radius: 3px;
    border: 1.8px solid black;
 
    background-color: rgb(182, 175, 175);
    height: 8vw;
    font-family: CaviarDreams;
    width: 100%;
}
.uplabel{
    margin-left: 10%;
    font-size: 2vw;
}
.downlabel{
    margin-left: 10%;
    font-size: 2vw;
}
.messageinput{
    margin-left: 0%;
   
    font-size: 1.5vw;
    border-radius: 3px;
    font-family: CaviarDreams;
    border: 1.8px solid black;
    background-color: rgb(182, 175, 175);
    height: 20vw;
    width: 82%;
}
.formlabel{
    font-size: 2vw;

}
.messagelabel{
  
    margin-left: 9%;
   
    font-family: CaviarDreams;
    font-weight: 800;
    font-size: 2vw;
    letter-spacing: 0.1vw;
}
.bigtext{
    text-align: right;
    margin-top: 20%;
    margin-right: 9%;
    font-size: 9vw;
    font-family: CaviarDreams;
    font-weight: 100;
}
.reachUs{
    text-align: right;
    margin-right: 11%;
    font-family: CaviarDreams;
    font-size: 3vw;
    line-height: 0.5vw;
    margin-bottom: 20%;
    margin-top: 7%;

}
.submit{
    width: 32%;
    margin-top: 10%;
    margin-left: 59%;
    padding: 3%;
    /* border: 4px solid black; */
    border: none;
    background-color: #2D85F3;
    font-weight: bold;
    font-size: 5vw;
    cursor: pointer;
    color: white;
    border-radius: 5px;
}
}