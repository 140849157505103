
.rootHomeG{
    display: flex; 
    flex-direction: column;
    height:120%;
    text-align: center;
    width: 100%;
    z-index: 1; 
}

.linkCardsG{
    position: relative;
    margin-top:12% ;
    margin-bottom: 10%;
}
.gridElementG{
    display: flex;
    position: relative;
    margin-top:3% ;
    /*box-shadow:  5px 5px 5px 5px rgba(0, 0, 0, 0.19); */
    width: 75%;
    left: 15%;
    border-radius: 10px;
    /*background-color: #ffffff;*/
}
.gridElementG:hover{
    transform: scale(1.05,1.05);
    cursor: pointer;
}
.gamebt{
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    padding: 4%;
    width: 67%;
    margin-bottom: 3%;
}
.gamebt:hover{
    transform: scaleX(1.1);
    cursor: pointer;
}
.cdsmtxtLinks{
    font-size: 1.1vw;
    font-weight: 750;
    margin-right: -3%;
    margin-left:-3%;
    text-decoration: none;
    color:white;

}
.cdsmtxtLinks:hover{
  
}
.cardTextG{
float: right;
margin-bottom: 0;
font-family: Arial, Helvetica, sans-serif ;
color:#000000; /*#32467B*/
padding-left: 18%;
padding-right: 0;
/* width: 40%; */
text-align: center;
z-index: 5;
left: 0;
margin-right: 1.4%;
margin-top: 19%;

}
.Right{
    margin-top: 13%;
}
.cdlgtxtG{
    font-size: 3.5vw;
    font-weight: 700;
    margin: -2%;
    color:#000000;
}
.lgchartxtG{
    font-size:2.5vw;
}
.cdmdtxtG{
    font-size: 2vw;
    margin: 0;
}
.cdsmtxtG{
    font-size: 1vw;
}

.cardImgG{
    position: relative;
    
  top: 0;
  width: 30%;
    height: 50%;
}
.portImg{
    position: ;
    float: left;
    height:  ;
    /* margin-right: -9%;
    margin-left: -8%;
    margin-top: -8%;
    margin-bottom: -7%; */
    margin: 5%;
    height: 17vw;
}
.appImgG{
    float: left;
  
    margin: 5%;
    margin-left: 15%; /*margin-left: 30%*/
    height: 16vw;
   
}
.gameImgG
{
    float: left;
   
    margin: 10%;
    margin-left:-12% ;
    height: 15vw;
}
.animImg{
    float: left;
  
    margin-top: 35%;
    margin-bottom: 32%;
    margin-left: 10%;
    height: 10.5vw; 
}

@media only screen and (min-width:950px){
    .cdsmtxtLinks{
        margin-left: 0%;
        margin-right: 0%;

    }
}
@media only screen and (max-width:600px){
    .Header{
        margin-top:12%;
        
    }.bigText{
        font-size: 15vw;
        margin-top:15%;
    }.smallText{
        font-size: 3.5vw;
        font-family: CaviarDreams;
    }
    .animImg{
        margin-top: 25%;
        margin-bottom: 25%;
    }
    .cardTextG{
       
        padding-left: 20%;
        width: 40%;
        text-align: center;
        z-index: 5;
        left: 0;
        margin-right: 1.4%;
        margin-top: 7%;
        }
        .cdsmtxtLinks{
            font-size: 2vw;
            
        
        }
        .gamebt{
            width: 80%;
        }
        .gridElementG{
         
         width: 70%;
            left: 15%;
            border-radius: 10px;
            background-color: white;
            
        }
        .linkCardsG{
            margin-bottom: 20%;
        }
        .cdlgtxtG{
            margin-top: -10%;
            font-size: 7vw;
        }
        .lgchartxtG{
            font-size: 6vw;
            margin-left: -18%;
        }
        .cdmdtxtG{
            margin-top: 0%;
            font-size: 4vw;
            margin-left: -5%;
        }
        .cdsmtxtG{
            margin-top: 3%;
            font-size: 2vw;
            margin-left: -6%;
            margin-bottom: 10%;
        }
        .cardImg{
          
        }
        .portImg{
          
            float: left;
            margin: 8%;
            margin-left: 20%;
            height: 25vw;
        }
        .appImgG{
            float: left;
          
            margin: 5%;
            margin-left: 20%;
            height: 25vw;
           
        }
        .gameImgG
        {
            float: left;
           
            margin: 5%;
            height: 25vw;
            margin-left: 15%;
        }
        .animImg{
            float: left;
            margin-top: 30%;
            margin-bottom: 25%;
            margin-left: 10%;
            height: 15.5vw; 
        }
}
