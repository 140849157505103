.rootAppN{
    /* background-color:black; */
    background:linear-gradient(to bottom, white 15%, black 10%);


}
.topApp{
    display: ;
    flex-direction: column;
    background-image: url("../images/uperr\ backgraound-min.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.mainbrain{
    position: absolute; 
    z-index: 5;
    width: 40vw;
    margin-top: 6.5vw;
    /* right: 0vw; */
    /* margin-bottom: 10%; */
    margin-left: -38%;
    
}
.brainImages{
    position: relative;    
    float: right ;

    width: 20vw;
}
.flame1N{
    position: absolute;  
    width: 3.42vw; 
    /* margin-top: 26.4%; */
    top:24.5vw;
    right: 11.29vw;  
    z-index: 5;
}
.flame2N{
    position: absolute;  
    width: 3.88vw; 
    top: 35vw;
    right: -14.573vw;  
    z-index: 10;
}
.flame3N{
    position: absolute;  
    width: 3vw;  
    top: 18.9vw;
    right: -18.0vw; 
    z-index: 10;

}
.light{
    animation-name: glowing;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
@keyframes glowing {
    from {  opacity: 0.5; }
    60%  {  opacity: 1; }
    to   {  opacity:0.5; }    
}
.gearN{
    position: absolute; 
    margin-top: 0;
    z-index: 5;
    width: 6vw;
    /* margin-top: 45vw; */
    top:43.5vw;
    right: -9vw;
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
@keyframes floating {
    from { transform: translate(0,  0px); }
    60%  { transform: translate(0, -90%); }
    to   { transform: translate(0, 0px); }    
}
.upApp{
    display: flex;
}
.AppHeading1{
    margin-top: 0%;
    font-size: 4vw;
    color:white;
    float: left;
    font-family: Humanist777;
    margin-left: 4%;
    width: 50%;
    margin-bottom: -0%;
}
.apph1{
    padding-top: 5%;
    margin-top: 12%;
    text-align: left;
    margin-bottom: -3%;
}
.apph2{
    margin-top: -5%;
    margin-left: -2%;
    text-align: left; 
    padding-bottom: 0%;

}

.rocketblock{
    margin-top: 0%;
  
}
.rockettxt{
    margin-left: 25%;
    width: 9%;
    margin-top: 14%;
    padding-bottom: 19%;
    padding-top: 0;
    color: white;
    font-size: 5vw;
    text-align: left;
    line-height: 1;
    font-family: Humanist777;
}
.rocketim{
    width: 92%;
    
    top: 65vw;
    margin-left: -51%;
    z-index: 4;
    position: absolute;
}
.bottomApp{
    z-index:-6 ;
    margin-top:-3.55%;
    margin-bottom:-8%;
    background-color: black;
    position:  ;
    background-image: url("../images/lower\ background-min.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.AppFooter{
    margin-top: 185%;
}
.cardSliderN{
    margin-top: 0%;
    padding-top: 40%;

}
.carouselRoot{
    padding-top: 45%;
    padding-bottom: 0%;
}
.SliderElementN{
    background-color: #4B69B4;
    /* display: block;
    position: ; */
     /* background-image: url("../images/big\ box\ for\ sliding-min.png");  */
   background-repeat: no-repeat;
  margin:8%;
border-radius: 15px;
box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
display: flex;
cursor: pointer;
}
.SliderElementN:hover{
transform: scale(1.05 1.05);
}






.lowerTextN{
    margin-top:10%;
    margin-left:5%;
    font-size: 3vw;
    color: white;
    text-align: left;
    font-family: Humanist777;
}
.smallgridN1{
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    }
.smallgridN2{
    margin-top: %;
    display: flex;
    flex-direction: row;
    }
.containerN{
    width: 48%;
    position: relative;
}
.containerN:hover{
    transform: scale(1.05,1.05);
    cursor: pointer;
}
.second{
    width: 35%;
}
.containerN1{  
    float: left;
    background-image: url("../images/sm1-min2.png");
    background-size: 81% 100%;
    background-repeat: no-repeat;
    margin-left: 5.5%;
    margin-right: -3%;
    margin-top: 2%;

}

.containerN2{
    float: right;
    margin-top: -1.8%;

    background-image: url("../images/sm2-min.png");
    background-size: 104% 114%;
}

.containerN3{  
    float: left;
    /* margin-top: -1.8%; */
    margin-left: -1%;

    background-image: url("../images/sm3.png");
    background-size: 108% 105%;
}
.containerN4{
    float: right;
    margin-left: 3.2%;
    background-image: url("../images/sm4.png");
    background-size: 103% 104%;
    margin-top: 0.5%;
}
.textN{
    position: ;
  
    color: white;
    margin-left: 46%;
    width: 35%;
    margin-top: 0%;
    text-align: left;
    font-family: Humanist777;
    font-size: 1.1vw;
}
.textN1{
    padding-top: 6%;
    margin-left: 39%;
    padding-bottom: 5%;
}
.textN2{
    padding-top: 13.6%;
    padding-bottom: 15%;
    margin-left: 51%;

}
.textN3{
    padding-top: 14.8%;
    padding-bottom: 15%;
    margin-left: 53%;
    width: 34%;
}
.textN4{
    padding-top: 14.5%;
    padding-bottom: 15%;
    margin-left: 51%;

}

/* .bgimgN{
    width: 100%;
}
.bgimgN:hover{
    scale: 1.1;
} */


/* .cards2-2N{
    position: ;
    top:60%;
    left:57%;
    width: 40%;
}
.cards2-2N:hover{
    transform: scale(1.1,1.1);
    cursor: pointer;
}
.cards2-3N{
    position: ;
    top:72%;
    left:2%;
    width: 40%;
}
.cards2-3N:hover{
    transform: scale(1.1,1.1);
    cursor: pointer;
} */
/* .cards2-4N{
    position: ;
    top:72%;
    left:57%;
    width: 40%;
}
.cards2-4N:hover{
    transform: scale(1.1,1.1);
    cursor: pointer;
} */
.learnmoreN{
   
    margin-left: 5%;
    width: 80%; 
    cursor: pointer;
    z-index: 10;
    color: white;
    border-radius: 10px ;
    border: none;
    font-size: 1.3vw;
    font-family: Fredoka One;
    padding: 6%; 
    background: #FF891C;
    transition:'1s cubic-bezier(0.075, 0.82, 0.165, 1) ;'
  }
  .learnmoreN:hover{
    transform: scale(1.1,1.1);
  }
  .contactUsN{
    margin-left: ;
    color: white;
    text-align: center;
   
    width: 100%;
    padding-bottom: 4%;
    
}
.contactTextN{
    font-size: 4vw;
  font-family: Humanist777;
}
.contactbtN{
  left:100%;
  cursor: pointer;
  z-index: 10;
  color: white;
  border-radius: 10px ;
  border: none;
  font-size: 3vw;
  font-family: Fredoka One;
  padding: 10px;
  background: #FF891C;
  transition:'1s cubic-bezier(0.075, 0.82, 0.165, 1) ;'
}
.contactbtN:hover{
    transform: scale(1.1,1.1);
}
@media only screen and (max-width:600px){

.rootAppN{
    margin-top: 8%;
    background:linear-gradient(to bottom, white 10%, black 10%);
}
.rocketim{
    top:73vw ;
}
.textN4{
    width: 28%;
}
.textN3{
    width: 32%;
}
.AppFooter{
    margin-top: 198%;
}
.contactTextN{
    font-size: 3.5vw;
}
.carousel .control-dots{
    bottom: -13px;
}
}
@media only screen and (max-width:400px){

    .rootAppN{
        margin-top: 9%;
    }
    .rocketim{
       
        top:80vw ;
        margin-left: -48%;
     
    }
    .AppFooter{
        margin-top: 202%;
    }
    }

